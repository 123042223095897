import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQuery,
  tagTypes: ['Notifications'],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => '/notifications',
      providesTags: ['Notifications'],
    }),
    markNotificationsAsRead: builder.mutation({
      query: (notificationIds) => ({
        url: '/notifications/mark-read',
        method: 'POST',
        body: { notificationIds },
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useMarkNotificationsAsReadMutation,
} = notificationsApi; 