import person1 from './person1.svg'
import auth from './auth.svg'
import person2 from './person2.svg'
import person3 from './person3.svg'
import person4 from './person4.svg'
import person5 from './person5.svg'
import whitestar from './whitestar.svg'
import ideaIcon from './ideaIcon.svg'
import logo from './transparent-logo.png'
import googleMail from './googleMail.svg'
import microsoft from './microsoft.svg'
import twitter from './twitter.svg'
import linkedIn from './linkedIn.svg'
import profile from './profile.svg'
import flag from './flag.svg'
import gold from './gold.png'
import article1 from './article1.svg'
import article2 from './article2.svg'
import article3 from './article3.svg'
import article4 from './article4.svg'
import article5 from './article5.svg'
import article6 from './article6.svg'
import sendIcon from './sendIcon.svg'
import backIcon from './backIcon.svg'
import cloud from './cloud.svg'
import commentimage from './commentimage.svg'
import newsletter from './newsletter.svg'
import articleView from './articleView.svg'
import share from './share.svg'
import aboutImage from './aboutImage.svg'
import verify from './verify.svg'
import book1 from './book1.svg'
import book2 from './book2.svg'
import ideaImage from './ideaImage.svg'
import generateIdea from './generateIdea.svg'
import timer from './timer.svg'
import addImage from './addImage.svg'
import home from './home.svg'
import article from './article.svg'
import comment from './comment.svg'
import imageUpload from './imageUpload.svg'
import paymentImage from './paymentImage.svg'
import facebook from './facebook.svg'
import twitterlogo from './twitterlogo.svg'
import linkedInlogo from './linkedInlogo.svg'
import instagramlogo from './instagramlogo.svg'
import wordImage from './wordImage.svg'
import pdflogo from './pdflogo.svg'
import imageIcon from './imageIcon.svg'
import downloadImage from './downloadImage.svg'
import calendar from './calendar.jpg'
import zero from './zero.svg'
import fire from './fire.svg'
import editorText from './editor-text.svg'
import editorImg from './editor-img.svg'
import editorCircle from './editor-circle.svg'
import editorVid from './editor-video.svg'
import paidarticle from './paidarticle.svg'
import youtube from './youtube.svg'
import profileImage from './profile.png'
import csvImage from './csvImage.svg'
import pen from './pen.svg'
import avatar from './avatar.jpg'
import star from './star.png'
import transparentLogo from './transparent-logo.png'
export {
    transparentLogo,
    star,
    avatar,
    comment,
    person1,
    auth,
    person2,
    person3,
    person4,
    person5,
    whitestar,
    ideaIcon,
    logo,
    googleMail,
    microsoft,
    twitter,
    linkedIn,
    profile,
    flag,
    gold,
    article1,
    article2,
    article3,
    article4,
    article5,
    article6,
    sendIcon,
    backIcon,
    commentimage,
    cloud,
    newsletter,
    articleView,
    share,
    aboutImage,
    verify,
    book1,
    book2,
    ideaImage,
    generateIdea,
    timer,
    addImage,
    home,
    article ,
    paymentImage,
    imageUpload,
    facebook,
    twitterlogo,
    linkedInlogo,
    instagramlogo,
    wordImage,
    pdflogo,
    downloadImage,
    imageIcon,
    calendar,
    zero,
    fire,
    editorText,
    editorImg,
    editorCircle,
    editorVid,
    paidarticle,
    youtube,
    profileImage,
    csvImage,
    pen
}

