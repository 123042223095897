import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from '../baseQuery';

export const articlesApi = createApi({
  reducerPath: 'articlesApi',
  baseQuery: baseQuery,
  tagTypes: [
    'Article',
    'Articles',
    'AuthorArticles',
    'Comments',
    'CategoryArticles',
  ],
  endpoints: (builder) => ({
    createArticle: builder.mutation({
      query: (articleData) => ({
        url: '/articles',
        method: 'POST',
        body: articleData,
      }),
      invalidatesTags: ['Articles', 'AuthorArticles', 'CategoryArticles'],
    }),
    getArticleAndAuthor: builder.mutation({
      query: (searchQuery) => ({
        url: '/articles/getAuthorAndArticle',
        method: 'POST',
        body: {
          name: searchQuery,
        },
      }),
    }),
    getAllArticles: builder.query({
      query: () => '/articles',
      providesTags: ['Articles'],
    }),
    getAuthorArticles: builder.query({
      query: (authorId) => `/articles/author/${authorId}`,
      providesTags: ['AuthorArticles'],
    }),

    getArticleById: builder.query({
      query: (id) => `/articles/${id}`,
      providesTags: (result, error, id) => [{ type: 'Article', id }],
    }),

    getAllArticlesPaginated: builder.mutation({
      query: ({ page, limit }) => ({
        url: `/articles/paginated`,
        method: 'POST',
        body: { page, limit },
      }),
      providesTags: ['Articles'],
    }),

    updateArticle: builder.mutation({
      query: ({ id, ...updatedData }) => ({
        url: `/articles/${id}`,
        method: 'PUT',
        body: updatedData,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Article', id },
        'Articles',
        'AuthorArticles',
        'CategoryArticles',
      ],
    }),

    deleteArticle: builder.mutation({
      query: (id) => ({
        url: `/articles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Articles', 'AuthorArticles', 'CategoryArticles'],
    }),
    // filter articles by category
    getArticlesByCategoryId: builder.query({
      query: ({ selectedInterest, userId }) =>
        `/articles/category/${selectedInterest}?userId=${userId}`,
      providesTags: (result, error, categoryId) => [
        { type: 'CategoryArticles', id: categoryId },
      ],
    }),
    // filter articles by category pagination
    getArticlesByCategoryIdPagination: builder.query({
      query: ({ selectedInterest, userId, page, limit }) =>
        `/articles/category/pagination/${selectedInterest}?userId=${userId}&page=${page}&limit=${limit}`,
      providesTags: (result, error, categoryId) => [
        { type: 'CategoryArticles', id: categoryId },
      ],
    }),
    getArticlesByCategoryIdPublic: builder.query({
      query: (categoryId) => `/articles/category/public/${categoryId}`,
      providesTags: (result, error, categoryId) => [
        { type: 'CategoryArticles', id: categoryId },
      ],
    }),
    getComments: builder.query({
      query: ({ articleId, filter }) =>
        `/articles/${articleId}/comments?filter=${filter}`,
      providesTags: (result, error, articleId) => [
        { type: 'Comments', id: articleId },
      ],
    }),

    addComment: builder.mutation({
      query: ({ articleId, comment }) => ({
        url: `/articles/${articleId}/comments`,
        method: 'POST',
        body: { comment },
      }),
      invalidatesTags: (result, error, { articleId }) => [
        { type: 'Comments', id: articleId }
      ], 
      invalidateTags: (result, error, { articleId }) => [
        { type: 'Article', id: articleId }
      ],
    }),
    // edit comment
    editComment: builder.mutation({
      query: ({ articleId, commentId, comment }) => ({
        url: `/articles/${articleId}/comments/${commentId}`,
        method: 'PUT',
        body: { comment },
      }),
      invalidatesTags: (result, error, { articleId }) => [
        { type: 'Comments', id: articleId },
      ],
    }),
    deleteComment: builder.mutation({
      query: ({ articleId, commentId }) => ({
        url: `/articles/${articleId}/comments/${commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { articleId }) => [
        { type: 'Comments', id: articleId },
      ],
    }),
    // delete comment
    likeArticle: builder.mutation({
      query: (articleId) => ({
        url: `/articles/${articleId}/like`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { articleId }) => [
        { type: 'Article', id: articleId },
        'Articles',
        'AuthorArticles',
        'CategoryArticles',
      ],
    }),

    unlikeArticle: builder.mutation({
      query: (articleId) => ({
        url: `/articles/${articleId}/unlike`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { articleId }) => [
        { type: 'Article', id: articleId },
        'Articles',
        'AuthorArticles',
        'CategoryArticles',
      ],
    }),
  }),
});

export const {
  useCreateArticleMutation,
  useGetArticlesByCategoryIdQuery,
  useGetArticlesByCategoryIdPublicQuery,
  useGetAllArticlesPaginatedMutation,
  useGetAllArticlesQuery,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useGetArticleByTopicMutation,
  useGetArticleByIdQuery,
  useUpdateArticleMutation,
  useDeleteArticleMutation,
  useGetAuthorArticlesQuery,
  useGetCommentsQuery,
  useAddCommentMutation,
  useLikeArticleMutation,
  useUnlikeArticleMutation,
  useGetArticleAndAuthorMutation,
} = articlesApi;
