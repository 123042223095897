import { Skeleton } from '@/components/ui/skeleton';

// Skeleton for ExploreArticleCard
export function ExploreArticleCardSkeleton() {
  return (
    <div className="group relative bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden grow">
      <div className="flex md:flex-row flex-col-reverse gap-6 p-5">
        {/* Image Container */}
        <div className="relative md:w-[280px] w-full h-[200px] md:h-[160px] cursor-pointer overflow-hidden rounded-xl">
          <Skeleton className="w-full h-full" />
        </div>

        {/* Content Container */}
        <div className="flex flex-col flex-1 gap-4 select-none">
          {/* Author Info */}
          <div className="flex items-center gap-3">
            <Skeleton className="w-9 h-9 rounded-full" />
            <div className="flex flex-col gap-2">
              <Skeleton className="h-4 w-24" />
              <Skeleton className="h-3 w-16" />
            </div>
            <Skeleton className="h-4 w-4 rounded-full" />
            <Skeleton className="h-4 w-12" />
          </div>

          {/* Article Title */}
          <div className="space-y-2.5">
            <Skeleton className="h-6 w-3/4" />
          </div>
        </div>
      </div>
    </div>
  );
}
