const isProduction = process.env.MODE === 'production';

const appConfig = {
  baseUrl: isProduction ? 'https://api.kitabh.com' : 'https://api.kitabh.com',
  frontendUrl: isProduction ? 'https://kitabh.com' : 'https://kitabh.com',
  apiPrefix: 'api',
  authenticatedEntryPath: '/dashboard',
  unAuthenticatedEntryPath: '/signin',
  tourPath: '/',
  locale: 'en',
};

export default appConfig;
