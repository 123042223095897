'use client';

import { Bell } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import { useGetNotificationsQuery } from '@/RTK/services/notificationsApi';

const NotificationIcon = ({className,styles}) => {
  const router = useRouter();
  const token = useSelector((state) => state.auth.token);
  
  // Move API call to this component
  const { data } = useGetNotificationsQuery(undefined, {
    skip: !token
  });

  // Calculate unread notifications count
  const unreadCount = data?.notifications?.filter(notification => !notification.read).length || 0;

  return (
    <div className={`md:flex relative ${className}`} style={styles}>
      <button
        onClick={() => router.push('/notifications')}
        className="p-2 hover:bg-gray-50 rounded-full transition-colors"
      >
        <Bell className="w-5 h-5 text-gray-600" />
        {unreadCount > 0 && (
          <span className="absolute top-0 -right-1 bg-red-500 text-white text-xs font-medium px-1.5 min-w-[20px] h-5 rounded-full flex items-center justify-center">
            {unreadCount > 99 ? '99+' : unreadCount}
          </span>
        )}
      </button>
    </div>
  );
};

export default NotificationIcon; 