import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQuery,
    tagTypes: ['User', 'Follow', 'Followers', 'Following', 'Bookmark'],
    endpoints: (builder) => ({
        getUser: builder.query({
            query: () => '/user/profile',
            providesTags : ['User'],
        }),
        getAuthorById:builder.query({
            query:(id)=>`/user/profile/${id}`
        }),
        getAuthorByUsername:builder.query({
            query:(username)=>`/user/${username}`
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: '/user/profile',
                method: 'PUT',
                body: data
            }),
            invalidatesTags:['User']
        }),
        updatePassword: builder.mutation({
            query: (data) => ({
                url: '/user/password',
                method: 'PUT',
                body: data
            }),
            invalidatesTags:['User']
        }),
        // following queries
        followUser: builder.mutation({
            query: (userId) => ({
              url: `/users/${userId}/follow`,
              method: 'POST',
            }),
            invalidatesTags: (result, error, userId) => [
                { type: 'Follow', id: userId },
                'Followers',
                'Following'
            ]
        }),
        getFollowers: builder.query({
            query: (userId) => `/users/${userId}/followers`,
            providesTags: ['Followers']
        }),
        getFollowings: builder.query({
            query: (userId) => `/users/${userId}/following`,
            providesTags: ['Following']
        }),
        bookmarkArticle:builder.mutation({
            query:(articleId)=>({
                url:`/users/bookmark/${articleId}`,
                method: 'POST',
            }),
            invalidatesTags:['User','bookmarkArticles']
        }),
        getBookmarkArticle:builder.query({
            query:()=>'/users/bookmark-articles',
            providesTags:['bookmarkArticles']
        }),
        getInterest:builder.query({
            query:()=>'/users/interests',
            providesTags:['interest']
        }),
        getIsFollowing: builder.query({
            query: (userId) => `/users/${userId}/is-following`,
            providesTags: (result, error, userId) => [{ type: 'Follow', id: userId }]
        }),
    }),
});

export const { 
    useGetUserQuery,
    useUpdateUserMutation ,
    useOnboardUpdateUserMutation,
    useUpdatePasswordMutation,
    useGetBookmarkArticleQuery,
    useGetAuthorByIdQuery,
    useFollowUserMutation,
    useGetFollowersQuery,
    useGetFollowingsQuery,
    useBookmarkArticleMutation,
    useToggleInterestMutation,
    useGetInterestQuery,
    useGetAuthorByUsernameQuery,
    useGetIsFollowingQuery                          
} = userApi;
