import { useState, useEffect } from 'react';
import { useLikeArticleMutation, useUnlikeArticleMutation } from '@/RTK/services/articleApi';

const useLike = (initialLikes = [], articleId, userId, refetchCallback) => {
  const [likes, setLikes] = useState(initialLikes);
  const [likeArticle] = useLikeArticleMutation();
  const [unlikeArticle] = useUnlikeArticleMutation();
  const isLiked = likes?.includes(userId);

  const handleLike = async () => {
    try {
      // Optimistically update the UI
      if (isLiked) {
        setLikes(prevLikes => prevLikes.filter(id => id !== userId));
        await unlikeArticle(articleId).unwrap();
      } else {
        setLikes(prevLikes => [...prevLikes, userId]);
        await likeArticle(articleId).unwrap();
      }

      // Only refetch if needed (e.g., to sync with server)
      if (refetchCallback) {
        refetchCallback();
      }
    } catch (error) {
      // Revert optimistic update on error
      if (isLiked) {
        setLikes(prevLikes => [...prevLikes, userId]);
      } else {
        setLikes(prevLikes => prevLikes.filter(id => id !== userId));
      }
      console.error('Error handling like:', error);
    }
  };

  // Update local likes when initialLikes changes
  useEffect(() => {
    setLikes(initialLikes);
  }, [initialLikes]);

  return { likes, isLiked, handleLike };
};

export default useLike; 