import { Skeleton } from '@/components/ui/skeleton';

export function NavbarSkeleton() {
  return (
    <div className="sticky top-0 z-50 bg-white border-b px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center h-16">
        {/* Left Section */}
        <div className="flex items-center gap-6">
          <Skeleton className="h-8 w-32 rounded" />
          <Skeleton className="h-6 w-24 hidden md:block" />
        </div>

        {/* Center Section */}
        <div className="hidden sm:flex justify-center max-w-md mx-auto">
          <Skeleton className="h-10 w-full rounded-full" />
        </div>

        {/* Right Section */}
        <div className="flex items-center gap-4">
          <Skeleton className="h-8 w-36 rounded-full hidden sm:block" />
          <Skeleton className="h-10 w-10 rounded-full" />
          <Skeleton className="h-8 w-8 rounded-full" />
        </div>
      </div>
    </div>
  );
}
