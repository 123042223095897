import { cn } from '@/lib/utils'

const Loader = ({ size = 'default', className }) => {
  const sizeClasses = {
    xs: 'h-3 w-3 border',
    sm: 'h-4 w-4 border-2',
    default: 'h-6 w-6 border-2',
    lg: 'h-8 w-8 border-3',
    xl: 'h-12 w-12 border-4'
  }

  return (
    <div className="flex items-center justify-center">
      <div
        className={cn(
          "rounded-full border-purple-200 border-t-purple-600 animate-spin",
          sizeClasses[size],
          className
        )}
        role="status"
        aria-label="Loading"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default Loader