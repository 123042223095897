import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "@/RTK/services/userApi"; // Import your API
import { signOut } from 'next-auth/react';

const initialState = {
  isLoggedIn: false,
  token: null,
  accountType: null,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
      // signOut({ callbackUrl: '/' });
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
  // Add this extraReducers to automatically update Redux state when RTK Query succeeds
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.updateUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
      }
    );
    builder.addMatcher(
      userApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.user;
      }
    );
  },
});

export const { login, logout, updateUser } = authSlice.actions;
export default authSlice.reducer;