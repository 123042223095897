import { Skeleton } from '@/components/ui/skeleton';

import { ExploreArticleCardSkeleton } from './ExploreArticleCardSkeleton';
import { NavbarSkeleton } from './NavbarSkeletonLoader';

export function SkeletonLoader() {
  return (
    <div className="flex flex-col w-full mt-[64px]">
      {/* Navbar Skeleton */}
      <NavbarSkeleton />

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row w-full ">
        {/* Main Content Skeleton */}
        <div className="lg:p-8 md:p-5 p-3 lg:border-l lg:w-2/3 flex flex-col gap-3">
          {/* Sticky Header Skeleton */}
          <Skeleton className="h-12 w-full rounded-lg" />
          <div className="h-[100px] w-full">
            <Skeleton className="h-12 w-full rounded-lg" />
          </div>

          {/* Articles Skeleton */}
          <div className="flex flex-col gap-4">
            {Array.from({ length: 3 }).map((_, index) => (
              <ExploreArticleCardSkeleton key={index} />
            ))}
          </div>
        </div>

        {/* Sidebar Skeleton */}
        <div className="lg:w-1/3 hidden lg:flex flex-col gap-8 p-8 border-l h-full bg-white overflow-y-auto bg-black">
          {/* Popular Articles Section */}
          <section className="space-y-5 mt-10 overflow-y-auto">
            <Skeleton className="h-6 w-1/2 rounded-lg mt-20" />
            {Array.from({ length: 7 }).map((_, index) => (
              <div key={index} className="flex items-center gap-3 p-3">
                <Skeleton className="w-12 h-12 rounded-full" />
                <div className="flex flex-col gap-2 w-full">
                  <Skeleton className="h-4 w-3/4" />
                  <Skeleton className="h-4 w-1/2" />
                </div>
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
}
export default SkeletonLoader;
