'use client';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSession } from 'next-auth/react';
import { login, logout } from '@/redux/slice/auth/authSlice';

export default function AuthStateListener() {
  const dispatch = useDispatch();
  const { data: session, status } = useSession();

  useEffect(() => {
    const fetchAndSetToken = async () => {
      if (status === 'authenticated' && session?.user) {

          dispatch(login({
            user: session?.user,
            token: session?.user?.token
          }));
      } else if (status === 'unauthenticated') {
        dispatch(logout());
      }
    };

    fetchAndSetToken();
  }, [session, status, dispatch]);

  return null;
} 