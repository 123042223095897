'use client';

import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ArrowUp, MessageCircle, MoreVertical, Edit, Trash2 } from 'lucide-react';
import ProfileImage from '@/components/ui/profile-image';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';
import {
  useAddCommentMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useGetCommentsQuery,
} from '@/RTK/services/articleApi.js';
import { useSelector } from 'react-redux';
import Loader from '../../../../utils/Loader';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { toast } from 'react-hot-toast';

const commentSchema = yup.object().shape({
  comment: yup.string().required('التعليق مطلوب').min(1, 'التعليق مطلوب'),
});

const CommentModal = ({ isOpen, onClose, articleId }) => {
  const [selected, setSelected] = useState('all');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');
  const [editComment, { isLoading: isUpdating }] = useEditCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const [addComment, { isLoading: isSubmitting }] = useAddCommentMutation();
  const router = useRouter();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(commentSchema),
    defaultValues: {
      comment: ''
    }
  });

  const onSubmit = async (data) => {
    try {
      await addComment({
        articleId,
        comment: data.comment
      }).unwrap();
      reset();
      await refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading, refetch } = useGetCommentsQuery(
    { articleId, filter: selected },
    { 
      skip: !isOpen,
      refetchOnMountOrArgChange: true
    }
  );
  const comments = data?.comments || [];
  const currentUserId = useSelector((state) => state.auth?.user?._id);
 console.log("comments", comments)
  const handleEdit = async (commentId) => {
    try {
      await editComment({
        articleId,
        commentId,
        comment: editedCommentText,
      }).unwrap();
      
      setEditingCommentId(null);
      setEditedCommentText('');
      await refetch();
    } catch (error) {
      toast.error(error.data?.message || 'فشل في تعديل التعليق');
    }
  };

  const handleDelete = async (commentId) => {
    if (window.confirm('هل أنت متأكد أنك تريد حذف هذا التعليق؟')) {
      try {
        await deleteComment({ articleId, commentId }).unwrap();
        await refetch();
      } catch (error) {
        toast.error(error.data?.message || 'فشل في حذف التعليق');
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md p-0 h-[90vh] sm:h-[85vh] w-[95vw] sm:w-[28rem] flex flex-col">
        <div className="px-4 py-3 border-b bg-white">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold flex items-center gap-2">
              <MessageCircle className="w-5 h-5" />
              التعليقات
            </h2>
            <Tabs value={selected} onValueChange={setSelected} className="w-auto">
              <TabsList className="grid w-[180px] grid-cols-2 p-1">
                <TabsTrigger value="all">الكل</TabsTrigger>
                <TabsTrigger value="new">الجديد</TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto custom-scrollbar">
          <div className="p-4 space-y-4">
            {isLoading ? (
              <div className="flex items-center justify-center py-10">
                <Loader />
              </div>
            ) : comments.length === 0 ? (
              <div className="flex flex-col items-center justify-center py-10 text-gray-500">
                <MessageCircle className="w-12 h-12 text-gray-300 mb-2" />
                <p className="text-sm">لا توجد تعليقات بعد</p>
              </div>
            ) : (
              comments.map((comment) => (
                <div 
                  key={comment.id} 
                  className={cn(
                    "group relative rounded-lg p-4 transition-colors",
                    comment.postedBy === currentUserId ? 'bg-purple-50' : 'bg-gray-50 hover:bg-gray-100'
                  )}
                  dir="rtl"
                >
                  <div className="flex items-start gap-4">
                    <Link href={`/@${comment?.username}`} className="flex-shrink-0 cursor-pointer"> 
                      <ProfileImage
                        src={comment.image}
                        alt={comment.username}
                        size="sm"
                        className="flex-shrink-0 cursor-pointer"
                    />
                    </Link>
                    
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center justify-between gap-2">
                        
                        <Link href={`/@${comment?.username}`} className="font-medium text-sm">{comment?.name || comment?.username}</Link>
                        <span className="text-xs text-gray-500">{comment?.date}</span>
                      </div>

                      {editingCommentId === comment.id ? (
                        <div className="mt-2 space-y-2">
                          <textarea
                            value={editedCommentText}
                            onChange={(e) => setEditedCommentText(e.target.value)}
                            className="w-full p-2 text-sm border rounded-lg text-right min-h-[80px] resize-none focus:outline-none focus:ring-2 focus:ring-purple-500"
                            dir="rtl"
                          />
                          <div className="flex justify-start gap-2">
                            <Button 
                              size="sm"
                              variant="ghost"
                              onClick={() => {
                                setEditingCommentId(null);
                                setEditedCommentText('');
                              }}
                            >
                              إلغاء
                            </Button>
                            <Button 
                              size="sm"
                              onClick={() => handleEdit(comment.id)}
                              disabled={isUpdating}
                            >
                              {isUpdating ? 'جارٍ الحفظ...' : 'حفظ'}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <p className="mt-1 text-sm text-gray-700 leading-relaxed break-words">
                          {comment.comment}
                        </p>
                      )}
                    </div>

                    {comment.postedBy === currentUserId && (
                      <div className="  transition-opacity">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                              <MoreVertical className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end" className="w-32">
                            <DropdownMenuItem 
                              onClick={() => {
                                setEditingCommentId(comment.id);
                                setEditedCommentText(comment.comment);
                              }}
                              className="text-gray-700"
                            >
                              <Edit className="h-4 w-4 ml-2" />
                              تعديل
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => handleDelete(comment.id)}
                              className="text-red-600"
                            >
                              <Trash2 className="h-4 w-4 ml-2" />
                              حذف
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="p-4 border-t bg-white mt-auto">
          <form onSubmit={handleSubmit(onSubmit)} className="flex gap-2" dir="rtl">
            <input
              {...register('comment')}
              className={cn(
                "flex-1 px-4 py-2 text-sm border rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 bg-gray-50",
                errors.comment && "border-red-500 focus:ring-red-500"
              )}
              placeholder="اكتب تعليقك هنا..."
              dir="rtl"
            />
            <Button 
              type="submit" 
              size="sm"
              disabled={isSubmitting}
              className="shrink-0 rounded-full h-10 w-10 p-0 bg-purple-600 hover:bg-purple-700"
            >
              {isSubmitting ? (
                <div className="w-4 h-4 border-2 border-white rounded-full animate-spin border-t-transparent" />
              ) : (
                <ArrowUp className="w-4 h-4 text-white" />
              )}
            </Button>
          </form>
          {errors.comment && (
            <p className="mt-1 text-xs text-red-500 text-right px-4">
              {errors.comment.message}
            </p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CommentModal;
