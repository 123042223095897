import { Skeleton } from '@/components/ui/skeleton';

import { ExploreArticleCardSkeleton } from './ExploreArticleCardSkeleton';

const SkeletonLoader = () => {
  return (
    <div className=" px-4">
      {/* Content Section Skeleton */}
      <div className=" ">
        {/* Article Cards Skeleton */}
        <div className="flex flex-wrap gap-6">
          {Array.from({ length: 4 }).map((_, index) => (
            <ExploreArticleCardSkeleton key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
