'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { Bookmark, Search } from 'lucide-react';
import { IoReaderOutline } from 'react-icons/io5';
import { RiHome5Line } from 'react-icons/ri';

import NotificationIcon from './NotificationIcon';

const MobileNavbar = () => {
  const [selected, setSelected] = useState('');
  const router = useRouter();

  const navItems = [
    {
      id: 'home',
      icon: RiHome5Line,
      path: '/explore',
    },
    {
      id: 'articles',
      icon: IoReaderOutline,
      path: '/articles',
    },
    {
      id: 'saved-articles',
      icon: Bookmark,
      path: '/saved-articles',
    },
    {
      id: 'notifications',
      component: NotificationIcon,
      path: '/notifications',
    },
  
    {
      id: 'search',
      icon: Search,
      path: '/explore/topics',
    },
   
   
   
  ];

  return (
    <nav className="md:hidden fixed bottom-0 left-0 right-0 z-50 bg-white border-t">
      <div className="flex justify-around items-center h-16">
        {navItems.map((item) => {
          if (item.component) {
            const Component = item.component;
            return (
              <button
                key={item.id}
                onClick={() => {
                  setSelected(item.id);
                  router.push(item.path);
                }}
                className={`  h-full transition-colors ${
                  selected === item.id
                    ? 'text-purple-600 border-t-2 border-purple-600'
                    : 'text-gray-400 hover:text-purple-600'
                }`}
              >
                <Component
                  isMobile={true}
                  className={`${selected === item.id ? 'text-purple-600' : 'text-gray-400 hover:text-purple-600 opacity-70'}`}
                />
              </button>
            );
          }

          const Icon = item.icon;
          return (
            <button
              key={item.id}
              onClick={() => {
                setSelected(item.id);
                router.push(item.path);
              }}
              className={`relative flex flex-col items-center justify-center w-16 h-full transition-colors ${
                selected === item.id
                  ? 'text-purple-600 border-t-2 border-purple-600'
                  : 'text-gray-400 hover:text-purple-600'
              }`}
            >
              <Icon className="w-5 h-5" />
            </button>
          );
        })}
      </div>
    </nav>
  );
};

export default MobileNavbar;
