import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Copy } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { 
  FacebookShareButton, 
  TwitterShareButton, 
  WhatsappShareButton, 
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon
} from 'react-share';

const ShareModal = ({ isOpen, onClose, article }) => {
  const shareUrl = typeof window !== 'undefined' 
    ? `${window.location.origin}/view-article/${article?._id}`
    : '';

  const shareButtons = [
    {
      Button: WhatsappShareButton,
      Icon: WhatsappIcon,
      title: 'واتساب',
      color: '#25D366'
    },
    {
      Button: TwitterShareButton,
      Icon: TwitterIcon,
      title: 'تويتر',
      color: '#1DA1F2'
    },
    {
      Button: LinkedinShareButton,
      Icon: LinkedinIcon,
      title: 'لينكد إن',
      color: '#0A66C2'
    },
    {
      Button: FacebookShareButton,
      Icon: FacebookIcon,
      title: 'فيسبوك',
      color: '#1877F2'
    }
  ];

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md w-[90%] rounded-md mx-auto p-4 sm:p-6">
        <div className="space-y-6">
          <div className="text-center space-y-2">
            <h3 className="text-lg font-semibold text-gray-900">
              مشاركة المقال
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              اختر منصة للمشاركة
            </p>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-6 px-2">
            {shareButtons.map(({ Button, Icon, title }) => (
              <div key={title} className="text-center">
                <Button
                  url={shareUrl}
                  title={article?.title}
                  className="flex flex-col items-center gap-2 sm:gap-3 transition-transform hover:scale-110 w-full"
                >
                  <Icon size={32} round />
                  <span className="text-xs font-medium text-gray-600">
                    {title}
                  </span>
                </Button>
              </div>
            ))}
          </div>

          <div className="relative px-2 sm:px-4">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-white px-2 sm:px-4 text-gray-500">أو</span>
            </div>
          </div>

          <div className="flex items-center justify-center px-2">
            <button
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(shareUrl);
                  toast.success('تم نسخ الرابط بنجاح', {
                    duration: 2000,
                    position: 'bottom-center',
                  });
                } catch (error) {
                  toast.error('حدث خطأ أثناء نسخ الرابط', {
                    duration: 2000,
                    position: 'bottom-center',
                  });
                }
              }}
              className="flex items-center gap-2 px-4 sm:px-6 py-2 text-sm font-medium text-gray-700 bg-gray-50 rounded-full hover:bg-gray-100 transition-colors w-full sm:w-auto justify-center"
            >
              <span>نسخ الرابط</span>
              <Copy className="w-4 h-4" />
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShareModal;