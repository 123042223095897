import Image from 'next/image';
import { Crown } from 'lucide-react';

import { avatar } from '../../../public/images';

const ProfileImage = ({
  userName,
  src,
  alt,
  size = 'md',
  isPremium = false,
  className = '',
}) => {
  const sizes = {
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-20 h-20',
  };
  console.log('username', userName);
  return (
    <div className={`relative flex-shrink-0 ${sizes[size]} ${className}`}>
      <div className="relative w-full h-full rounded-full overflow-hidden ring-2 ring-purple-100">
        {src ? (
          <Image
            src={src || avatar}
            alt={alt || 'Profile'}
            fill
            className="object-cover"
            sizes={`(max-width: 768px) ${size === 'lg' ? '80px' : '32px'}, ${size === 'lg' ? '80px' : '40px'}`}
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center">
            <span className="text-sm font-medium text-purple-600">
              {userName?.charAt(0) || 'A'}
            </span>
          </div>
        )}
      </div>
      {/* {isPremium && (
        <span className="absolute -top-1 -right-1">
          <Crown className="w-4 h-4 text-yellow-500 fill-yellow-500" />
        </span>
      )} */}
    </div>
  );
};

export default ProfileImage;
